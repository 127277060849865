import React from 'react'
import '../css/documents.css';
import { Border } from '../components/IndexPage'
import { HeroContainer, Title } from '../components/SubIndex'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import StockList from '../assets/pdf/Stock_List.pdf'
import SellSheet from '../assets/pdf/SellSheet.pdf'
import Sustain from '../assets/pdf/Sustainability_Facility_Improvements.pdf'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Footer } from '../components/Footer'
import heroVideo from '../assets/videos/ss_web_hero_2024.mp4'
import heroVideoMobal from '../assets/videos/ss_web_hero_2024.mp4'

const Documents = () => { 
  const data = useStaticQuery(graphql`
  query {
      mobileImage: file(relativePath: { eq: "hero-image.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "20200203-05_US_Stewart_Sutherland-190b.jpg" }) {
         childImageSharp {
           # Specify a fixed image and fragment.
           # The default width is 400 pixels
           fluid (quality: 100) {
             ...GatsbyImageSharpFluid
           }
         }
       }
       category_1: file(relativePath: { eq: "240-Baker-Deli-Grocery-Image.jpg" }) {
         childImageSharp {
           fluid(quality: 90) {
             ...GatsbyImageSharpFluid
           }
         }
       }
       category_2: file(relativePath: { eq: "Fast-Food-Concessions.jpg" }) {
         childImageSharp {
           fluid(quality: 90) {
             ...GatsbyImageSharpFluid
           }
         }
       }
       category_3: file(relativePath: { eq: "Pizza-Subs-Takeout.jpg" }) {
         childImageSharp {
           fluid(quality: 90) {
             ...GatsbyImageSharpFluid
           }
         }
       }
       category_4: file(relativePath: { eq: "Silverware.jpg" }) {
         childImageSharp {
           fluid(quality: 90) {
             ...GatsbyImageSharpFluid
           }
         }
       }
   }
  `)


  return (
    <Layout>
      <div className='relative z-0'>
        <div className='md:hidden'>
          <video loading='lazy' className='-mt-12 md:-mt-6 md:mb-0 w-full herovideo' autoPlay playsInline loop muted>
            <source src={heroVideoMobal} type='video/mp4'/>
          </video>
        </div>
        <div className='hidden md:block md:w-full'>
          <video loading='lazy' className='-mt-12 md:-mt-6 md:mb-0 w-full herovideo' autoPlay playsInline loop muted>
            <source src={heroVideo} type='video/webm'/>
          </video>
        </div>
      {/* <div className='relative z-0'>
        <div className='md:hidden bg-black'>
         <Img
            fluid={data.mobileImage.childImageSharp.fluid}
            className='-mt-12 md:-mt-6 md:mb-0 w-full'
            alt='hero-image'
            style={{ height: 300, opacity: 0.7, }}
         />
        </div>
        <div className='hidden md:block md:w-full bg-black'>
         <Img
            fluid={data.desktopImage.childImageSharp.fluid}
            className='-mt-12 md:-mt-6 md:mb-0 w-full'
            alt='hero-image'
            style={{ height: 420, opacity: 0.7, }}
         />
        </div> */}
        <HeroContainer>
          <Title className='inline-block'>
            Documents
            <Border thick className='mt-2 bg-primary' />
          </Title>
          <div className='max-w-md xl:max-w-2xl'>
            <p className='xl:block mt-4 open-sans text-gray-100 tracking-wide text-base md:text-base'> 
            </p>
          </div>
        </HeroContainer>
      </div>
      <div className='download-wrap'>
          <a
            href={SellSheet}
            target='_blank'
            className='download-1 download p-2 my-2 bg-primary text-white'
          >
            Sell Sheet PDF
          </a>
         <a
            href={Sustain}
            target='_blank'
            className='download-1 download p-2 my-2 bg-primary text-white'
          >
            Sustainability Improvements PDF
          </a>
         <a
            href={StockList}
            target='_blank'
            className='download-1 download p-2 my-2 bg-primary text-white'
          >
            Stock List PDF
          </a>
  
      </div>
      <div className='container p-0 xl:p-6 lg:p-6 md:py-24  '>
         <div className='itembox'>
            <div className='line-break'></div>
            <iframe className="mt-8" src="https://drive.google.com/file/d/1YGPWxHOee6-zW3dd2wKnSLMaP2QSaDZx/preview" width="100%" height="600" allow="autoplay"></iframe>
            <a
              href={SellSheet}
              download
              className='text-right my-8 bg-gray-200 p-2 rounded'
            >
              Download Sell Sheet
            </a>
          </div>
          <div className='itembox'>

            <iframe className="mt-8" src="https://drive.google.com/file/d/1Qi8ayApN1xcevkQgPHYnjykJLmnH_KCz/preview" width="100%" height="600" allow="autoplay"></iframe>
            <div className='line-break'></div>
            <a
              href={Sustain}
              download
              className='text-right my-8 bg-gray-200 p-2 rounded'
            >
              Download Sustainability Improvements
            </a>
          </div>
          <div className='itembox'>
            <div className='line-break'></div>
            <iframe className="mt-8" src="https://drive.google.com/file/d/1df1e2pFUOrW-2kMzY23qWWtIwbNMvgNH/preview" width="100%" height="600" allow="autoplay"></iframe>
            <a
              href={StockList}
              download
              className='text-right my-8 bg-gray-200 p-2 rounded'
            >
              Download Stock List
            </a>
          </div>
      </div>
      <Footer />
    </Layout>
   )
}

export default Documents
